<template>
  <div class="body-bg">
    <page-navigate :blankType="true"></page-navigate>
    <div :class="[$t('common.requestLang'), $t('common.type')]">
      <div :class="[$t('common.fontFamily'), $t('common.lang'), $t('common.type')]">
        <section class="section1 show">
          <img class="logo_banner" :src="$t('win3.logo')" alt="" />
          <img class="jiangbei_banner" src="@/assets/image/win/jiangbei_banner.png" alt="" />
          <img class="b_left" src="@/assets/image/win/b_left.png" alt="" />
          <img class="b_right" src="@/assets/image/win/b_right.png" alt="" />
          <div class="section1-title-box">
            <h1 :class="$t('common.fontFamilyTitle')">
              <span class="ebc">{{ $t('win3.EBC') }}</span>
              <span class="text">{{ $t('win3.全球百万美金交易大赛Ⅱ') }}</span>
            </h1>
            <div class="subtitle" :class="$t('common.fontFamilyTitle')">
              <div class="subtitle-line-left"></div>
              <span v-html="$t('win3.免费复制冠军交易')"></span>
              <img class="title-icon" src="@/assets/image/win/title_icon.svg" alt="" />
              <span v-html="$t('win3.共赴巴萨颁奖盛典')"></span>
              <div class="subtitle-line-right"></div>
            </div>
          </div>
        </section>

        <p class="section1-intro" v-html="$t('win3.EBC全球百万美元交易大赛')">
        </p>
        <div class="section2f-vectors">
          <span class="iconfont icon-Vector red"></span>
          <span class="iconfont icon-Vector blue"></span>
        </div>
        <section class="section2f">
          <div class="section2-header">
            <p class="section2-card-label" :class="$t('common.fontFamilyTitle')">{{ $t('win3.大赛奖池') }}</p>
            <img class="jiangjin" src="@/assets/image/win/jiangjin.svg" alt="" />
          </div>

          <div class="section2f-card-box">
            <div class="section2f-card">
              <p class="p1  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.一键跟单') }}</p>
              <p class="p2" v-html="$t('win3.免费复制冠军收益')"></p>
            </div>
            <div class="section2f-card">
              <p class="p1  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.公开透明') }}</p>
              <p class="p2">{{ $t('win3.观摩冠军交易技巧') }}</p>
            </div>
            <div class="section2f-card">
              <p class="p1  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.推荐有礼') }}</p>
              <p class="p2">{{ $t('win3.推荐有奖上不封顶') }}</p>
            </div>
          </div>
        </section>
        <section class="section2">
          <h3 class="tab-title  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.双赛道机制') }}</h3>
          <p v-if="section2ContentFlag" class="section2-intro">{{ $t('win3.以交易场的传控技术角逐百万') }}</p>
          <p v-else class="section2-intro">{{ $t('win3.像天才新星释放天赋「一球成名」') }}</p>
          <div class="buttons-group">
            <a id="section2button1" @click="handleChange(true)" href="javascript:;" class="group-button-left "
              :class="{ 'group-button-active': section2ContentFlag }">{{
                $t('win3.梦之队') }}</a>
            <a id="section2button2" @click="handleChange(false)" href="javascript:;" class="group-button-right"
              :class="{ 'group-button-active': !section2ContentFlag }">{{
                $t('win3.青训营') }}</a>
          </div>
          <div v-if="section2ContentFlag" class="section2-content">
            <div v-if="section2ContentFlag" class="pc">
              <div class="reward-list-pc reward">
                <div class="reward-item reward-item-4">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.第四至五名') }}</p>
                    <p class="p2">{{ $t('win3.$5000') }}</p>
                  </div>
                </div>
                <div class="reward-item reward-item-2">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.亚军') }}</p>
                    <p class="p2">{{ $t('win3.$30000') }}</p>
                  </div>
                </div>
                <div class="reward-item reward-item-1">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.冠军') }}</p>
                    <p class="p2">{{ $t('win3.$1,000,000交易账号或$200,000现金') }}</p>
                  </div>
                </div>
                <div class="reward-item reward-item-3">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.季军') }}</p>
                    <p class="p2">{{ $t('win3.$10000') }}</p>
                  </div>
                </div>
                <div class="reward-item reward-item-5">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.第六至十名') }}</p>
                    <p class="p2">{{ $t('win3.$3000') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mobile">
              <div class="reward-list">
                <swiper ref="reward-list" :options="swiperRewardOptions">
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-1">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.冠军') }}</p>
                        <p class="p2">{{ $t('win3.$1,000,000交易账号或$200,000现金') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-2">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.亚军') }}</p>
                        <p class="p2">{{ $t('win3.$30000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-3">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.季军') }}</p>
                        <p class="p2">{{ $t('win3.$10000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-4">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.第四至五名') }}</p>
                        <p class="p2">{{ $t('win3.$5000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-5">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.第六至十名') }}</p>
                        <p class="p2">{{ $t('win3.$3000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
          <div v-if="!section2ContentFlag" class="section2-content ">
            <div v-if="!section2ContentFlag" class="pc">
              <div class="reward-list-pc reward">
                <div class="reward-item reward-item-4">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.第四至五名') }}</p>
                    <p class="p2">{{ $t('win3.$2000') }}</p>
                  </div>
                </div>
                <div class="reward-item reward-item-2">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.亚军') }}</p>
                    <p class="p2">{{ $t('win3.$5000') }}</p>
                  </div>
                </div>
                <div class="reward-item reward-item-1">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.冠军') }}</p>
                    <p class="p2">{{ $t('win3.$30000') }}</p>
                  </div>
                </div>
                <div class="reward-item reward-item-3">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.季军') }}</p>
                    <p class="p2">{{ $t('win3.$3000') }}</p>
                  </div>
                </div>
                <div class="reward-item reward-item-5">
                  <div class="reward-item-jiangbei"></div>
                  <div class="reward-item-content">
                    <p class="p1">{{ $t('win3.第六至十名') }}</p>
                    <p class="p2">{{ $t('win3.$1000') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mobile">
              <div class="reward-list2">
                <swiper ref="reward-list2" :options="swiperReward2Options">
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-1">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.冠军') }}</p>
                        <p class="p2">{{ $t('win3.$30000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-2">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.亚军') }}</p>
                        <p class="p2">{{ $t('win3.$5000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-3">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.季军') }}</p>
                        <p class="p2">{{ $t('win3.$3000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-4">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.第四至五名') }}</p>
                        <p class="p2">{{ $t('win3.$2000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="reward-item-m reward-item-m-5">
                      <div class="reward-item-m-content">
                        <p class="p1">{{ $t('win3.第六至十名') }}</p>
                        <p class="p2">{{ $t('win3.$1000') }}</p>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
          <div class="buttons mt-80">
            <a :href="signupUrl" class="section-button" target="_blank">{{ $t('win3.立即参赛') }}</a>
          </div>
        </section>
        <section class="section2b">
          <video muted="muted" autoplay="autoplay" loop="loop" playsinline poster="@/assets/image/win/FCB-3.jpg"
            class="section2b-video pc">
            <source src="@/assets/videos/FCB_1 1.mp4" type="video/mp4" />
          </video>
          <div style="position: relative;z-index: 9;">
            <h3 class="tab-title  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.巴萨荣耀之旅') }}</h3>
            <div class="section2b-vectors">
              <span class="iconfont icon-Vector red"></span>
              <span class="iconfont icon-Vector blue"></span>
            </div>
            <p class="section2b-intro" v-html="$t('win3.每笔订单都是攻守博弈的艺术')">
            </p>
            <div class="section2b-content">
              <div class="section2b-item">
                <div class="section2b-line"></div>
                <img class="section2b-item-img" src="@/assets/image/win/section2b_1.png" alt="" />
                <h4>{{ $t('win3.邀您赴巴萨训练营') }}</h4>
                <p>{{ $t('win3.亲身体验冠军的致胜之旅') }}</p>
                <div class="section2b-line"></div>
              </div>
              <div class="section2b-item">
                <div class="section2b-line"></div>
                <img class="section2b-item-img" src="@/assets/image/win/section2b_2.png" alt="" />
                <h4>{{ $t('win3.于巴萨博物馆为您加冕') }}</h4>
                <p>{{ $t('win3.巴萨颁奖典礼') }}</p>
                <div class="section2b-line"></div>
              </div>
              <div class="section2b-item">
                <div class="section2b-line"></div>
                <img class="section2b-item-img" src="@/assets/image/win/section2b_3.png" alt="" />
                <h4>{{ $t('win3.巴萨绿茵场现场观赛') }}</h4>
                <p>{{ $t('win3.亲临VIP坐席见证球星逐梦诺坎普') }}</p>
                <div class="section2b-line"></div>
              </div>
            </div>
            <div class="section2b-content-m mobile">
              <video muted="muted" autoplay="autoplay" loop="loop" playsinline poster="@/assets/image/win/FCB-3.jpg"
                class="section2b-video">
                <source src="@/assets/videos/FCB_1 1.mp4" type="video/mp4" />
              </video>
              <swiper ref="section2b" :options="swiperSection2bOptions">
                <swiper-slide>
                  <div class="section2b-item">
                    <div class="section2b-line"></div>
                    <img class="section2b-item-img" src="@/assets/image/win/section2b_1.png" alt="" />
                    <h4>{{ $t('win3.邀您赴巴萨训练营') }}</h4>
                    <p>{{ $t('win3.亲身体验冠军的致胜之旅') }}</p>
                    <div class="section2b-line"></div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="section2b-item">
                    <div class="section2b-line"></div>
                    <img class="section2b-item-img" src="@/assets/image/win/section2b_2.png" alt="" />
                    <h4>{{ $t('win3.于巴萨博物馆为您加冕') }}</h4>
                    <p>{{ $t('win3.巴萨颁奖典礼') }}</p>
                    <div class="section2b-line"></div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="section2b-item">
                    <div class="section2b-line"></div>
                    <img class="section2b-item-img" src="@/assets/image/win/section2b_3.png" alt="" />
                    <h4>{{ $t('win3.巴萨绿茵场现场观赛') }}</h4>
                    <p>{{ $t('win3.亲临VIP坐席见证球星逐梦诺坎普') }}</p>
                    <div class="section2b-line"></div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </section>

        <section class="section3">
          <h3 class="tab-title  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.大赛推荐官') }}</h3>
          <div class="section3-content">
            <p class="p1">{{ $t('win3.交易即享推荐奖励') }}</p>
            <div class="section3-group">
              <div class="section3-group-card section3-group-card1">
                <div class="section3-group-card-left" v-html="$t('win3.梦之队HTML')">
                </div>
                <div class="section3-group-card-right">
                  <p class="section3-group-card-value">
                    <img class="number-300" src="@/assets/image/win/300.svg" alt="" /><span class="unit">{{
                      $t('win3./人') }}</span>
                  </p>
                </div>
              </div>
              <div class="section3-group-card section3-group-card2">
                <div class="section3-group-card-left" v-html="$t('win3.青训营HTML')">
                </div>
                <div class="section3-group-card-right">
                  <p class="section3-group-card-value">
                    <img class="number-30" src="@/assets/image/win/30.svg" alt="" /><span class="unit">{{ $t('win3./人')
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <p class="p1 mt-46">{{ $t('win3.3步推荐') }}</p>
            <div class="section3-card">
              <div class="section3-card-steps">
                <div class="section3-card-step">
                  <img class="step-img" src="@/assets/image/win/step1.png" alt="" />
                  <div class="section3-card-step-value  " :class="$t('common.fontFamilyTitle')">
                    {{ $t('win3.推荐好友') }}
                  </div>
                </div>
                <img class="right-img" src="@/assets/image/win/section3_right.png" alt="" />
                <div class="section3-card-step">
                  <img class="step-img" src="@/assets/image/win/step2.png" alt="" />
                  <div class="section3-card-step-value  " :class="$t('common.fontFamilyTitle')">
                    {{ $t('win3.好友交易') }}
                  </div>
                </div>
                <img class="right-img" src="@/assets/image/win/section3_right.png" alt="" />
                <div class="section3-card-step">
                  <img class="step-img" src="@/assets/image/win/step3.png" alt="" />
                  <div class="section3-card-step-value  " :class="$t('common.fontFamilyTitle')">
                    {{ $t('win3.赢得奖金') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons mt-56">
              <a :href="signupUrl" class="section-button" target="_blank">{{ $t('win3.立即推荐') }} </a>
            </div>
          </div>
        </section>
        <section class="section4">
          <h3 class="tab-title  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.实时赛况') }}</h3>

          <div class="buttons-group mt-56">
            <a id="section4button1" @click="handleChangeBang(true)" href="javascript:;" class="group-button-left"
              :class="{ 'group-button-active': section4ContentFlag }">{{
                $t('win3.梦之队') }}</a>
            <a id="section4button2" @click="handleChangeBang(false)" href="javascript:;" class="group-button-right"
              :class="{ 'group-button-active': !section4ContentFlag }">{{
                $t('win3.青训营') }}</a>
          </div>
          <div v-show="section4ContentFlag" class="section4-content">
            <div class="section4-table pc" :class="[$t('common.fontFamily')]">
              <div class="th">
                <div class="td">{{ $t('win3.排名') }}</div>
                <div class="td">{{ $t('win3.昵称') }}</div>
                <div class="td">{{ $t('win3.账号') }}</div>
                <div class="td">{{ $t('win3.收益额') }}
                  <div class="tips-box">
                    <span class="iconfont icon-a-tipsicon"> </span>
                    <div class="win-my-tips2">{{ $t('win3.收益额=期末净值+出金-入金') }}</div>
                  </div>
                </div>
                <div class="td">{{ $t('win3.奖金') }}</div>
                <div class="td">{{ $t('win3.跟单') }}</div>
              </div>
              <div class="tr" v-for="(item, index) in datalist" :key="index">
                <div class="td"><img v-if="item.Rank == 1" width="40rem" src="@/assets/image/win/rank1.png" alt="">
                  <img v-if="item.Rank == 2" width="40rem" src="@/assets/image/win/rank2.png" alt="">
                  <img v-if="item.Rank == 3" width="40rem" src="@/assets/image/win/rank3.png" alt="">
                  <span v-if="item.Rank != 1 && item.Rank != 2 && item.Rank != 3">{{ item.Rank }}</span>
                </div>
                <div class="td"
                  :class="{ 'pre-light': (item.AccountNumber == '1323672' || item.AccountNumber == '6425345') }">
                  {{ item.Nickname }}
                  <div v-if="item.preRank" class="win-my-tips">{{ item.preRank }}</div>
                </div>
                <div class="td">{{ item.AccountNumber }}</div>
                <div class="td">{{ item.Profit }}</div>
                <div class="td">{{ item.Prize }}</div>
                <div class="td">
                  <a :href="signupUrl" class="button-text" target="_blank">{{ $t('win3.跟单') }}</a>
                </div>
              </div>
              <div v-if="datalist.length == 0" class="no-data">
                {{ $t('win3.暂未开赛') }}
              </div>
            </div>
            <div class="mobile">
              <div v-if="datalist.length != 0" class="section4-list ">
                <swiper ref="section4-list1" :options="swiperSection4ListOptions">
                  <swiper-slide class="inline-block" v-for="(item, index) in datalist" :key="index">
                    <div class="section4-item">
                      <div class="section4-item-top">
                        <img class="section4-item-rank-left" src="../assets/image/win/rank_left.svg" />
                        <span class="section4-item-rank" :class="{ 'red': index == 0 }">{{
                          item.Rank }}</span>
                        <img class="section4-item-rank-right" src="../assets/image/win/rank_right.svg" />
                      </div>
                      <div class="section4-item-content">
                        <div class="section4-item-label">{{ $t('win3.昵称') }}</div>
                        <div class="section4-item-value"
                          :class="{ 'pre-light': (item.AccountNumber == '1323672' || item.AccountNumber == '6425345') }">
                          {{ item.Nickname }}
                          <div v-if="item.preRank" class="win-my-tips">{{ item.preRank }}</div>
                        </div>
                        <div class="section4-item-line"></div>
                        <div class="section4-item-label">{{ $t('win3.账号') }}</div>
                        <div class="section4-item-value">
                          {{ item.AccountNumber }}
                        </div>
                        <div class="section4-item-line"></div>
                        <div class="section4-item-label">{{ $t('win3.收益额') }}
                          <div class="tips-box">
                            <span class="iconfont icon-a-tipsicon"> </span>
                            <div class="win-my-tips2">{{ $t('win3.收益额=期末净值+出金-入金') }}</div>
                          </div>
                        </div>
                        <div class="section4-item-value red-light">
                          {{ item.Profit }}
                        </div>
                        <div class="section4-item-line"></div>
                        <div class="section4-item-label">{{ $t('win3.奖金') }}</div>
                        <div class="section4-item-value font-weight-400">
                          {{ item.Prize }}
                        </div>
                        <img class="section4-item-bg" src="@/assets/image/win/section4_item_bg.png" alt="" />
                      </div>
                      <a :href="signupUrl" class="section4-item-item-button" target="_blank">{{
                        $t('win3.跟单') }}</a>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <div v-if="datalist.length == 0" class="section4-table" :class="[$t('common.fontFamily')]">
                <div class="th">
                  <div class="td">{{ $t('win3.排名') }}</div>
                  <div class="td">{{ $t('win3.昵称') }}</div>
                  <div class="td">{{ $t('win3.账号') }}</div>
                  <div class="td">{{ $t('win3.收益额') }}
                    <div class="tips-box">
                      <span class="iconfont icon-a-tipsicon"> </span>
                      <div class="win-my-tips2">{{ $t('win3.收益额=期末净值+出金-入金') }}</div>
                    </div>
                  </div>
                  <div class="td">{{ $t('win3.奖金') }}</div>
                  <div class="td">{{ $t('win3.跟单') }}</div>
                </div>
                <div class="no-data">{{ $t('win3.暂未开赛') }}</div>
              </div>
            </div>
          </div>
          <div v-show="!section4ContentFlag" class="section4-content">
            <div class="section4-table new-table pc" :class="[$t('common.fontFamily')]">
              <div class="th">
                <div class="td">{{ $t('win3.排名') }}</div>
                <div class="td">{{ $t('win3.昵称') }}</div>
                <div class="td">{{ $t('win3.账号') }}</div>
                <div class="td">{{ $t('win3.收益率') }}
                  <div class="tips-box">
                    <span class="iconfont icon-a-tipsicon"> </span>
                    <div class="win-my-tips2">{{ $t('win3.收益率=期末净值/账号累积入金') }}</div>
                  </div>
                </div>
                <div class="td">{{ $t('win3.奖金') }}</div>
                <div class="td">{{ $t('win3.跟单') }}</div>
              </div>
              <div class="tr" v-for="(item, index) in datalist2" :key="index">
                <div class="td"><img v-if="item.Rank == 1" width="40rem" src="@/assets/image/win/rank1.png" alt="">
                  <img v-if="item.Rank == 2" width="40rem" src="@/assets/image/win/rank2.png" alt="">
                  <img v-if="item.Rank == 3" width="40rem" src="@/assets/image/win/rank3.png" alt="">
                  <span v-if="item.Rank != 1 && item.Rank != 2 && item.Rank != 3">{{ item.Rank }}</span>
                </div>
                <div class="td"
                  :class="{ 'pre-light': (item.AccountNumber == '1323672' || item.AccountNumber == '6425345') }">
                  {{ item.Nickname }}
                  <div v-if="item.preRank" class="win-my-tips">{{ item.preRank }}</div>
                </div>
                <div class="td">{{ item.AccountNumber }}</div>
                <div class="td">{{ item.ProfitRate }}</div>
                <div class="td">{{ item.Prize }}</div>
                <div class="td">
                  <a :href="signupUrl" class="button-text" target="_blank">{{ $t('win3.跟单') }}</a>
                </div>
              </div>
              <div v-if="datalist2.length == 0" class="no-data">
                {{ $t('win3.暂未开赛') }}
              </div>
            </div>
            <div class="mobile">
              <div v-if="datalist2.length != 0" class="section4-list ">
                <swiper ref="section4-list2" :options="swiperSection4List2Options">
                  <swiper-slide class="inline-block" v-for="(item, index) in datalist2" :key="index">
                    <div class="section4-item">
                      <div class="section4-item-top">
                        <img class="section4-item-rank-left" src="../assets/image/win/rank_left.svg" />
                        <span class="section4-item-rank" :class="{ 'red': index == 0 }">{{
                          item.Rank }}</span>
                        <img class="section4-item-rank-right" src="../assets/image/win/rank_right.svg" />
                      </div>
                      <div class="section4-item-content">
                        <div class="section4-item-label">{{ $t('win3.昵称') }}</div>
                        <div class="section4-item-value"
                          :class="{ 'pre-light': (item.AccountNumber == '1323672' || item.AccountNumber == '6425345') }">
                          {{ item.Nickname }}
                          <div v-if="item.preRank" class="win-my-tips">{{ item.preRank }}</div>
                        </div>
                        <div class="section4-item-line"></div>
                        <div class="section4-item-label">{{ $t('win3.账号') }}</div>
                        <div class="section4-item-value">
                          {{ item.AccountNumber }}
                        </div>
                        <div class="section4-item-line"></div>
                        <div class="section4-item-label">{{ $t('win3.收益率') }}
                          <div class="tips-box">
                            <span class="iconfont icon-a-tipsicon"> </span>
                            <div class="win-my-tips2">{{ $t('win3.收益率=期末净值/账号累积入金') }}</div>
                          </div>
                        </div>
                        <div class="section4-item-value red-light">
                          {{ item.ProfitRate }}
                        </div>
                        <div class="section4-item-line"></div>
                        <div class="section4-item-label">{{ $t('win3.奖金') }}</div>
                        <div class="section4-item-value font-weight-400">
                          {{ item.Prize }}
                        </div>
                        <img class="section4-item-bg" src="@/assets/image/win/section4_item_bg.png" alt="" />
                      </div>
                      <a :href="signupUrl" class="section4-item-item-button" target="_blank">{{
                        $t('win3.跟单') }}</a>
                    </div>
                  </swiper-slide>
                </swiper>

              </div>
              <div v-if="datalist2.length == 0" class="section4-table new-table" :class="[$t('common.fontFamily')]">
                <div class="th">
                  <div class="td">{{ $t('win3.排名') }}</div>
                  <div class="td">{{ $t('win3.昵称') }}</div>
                  <div class="td">{{ $t('win3.账号') }}</div>
                  <div class="td">{{ $t('win3.收益率') }}
                    <div class="tips-box">
                      <span class="iconfont icon-a-tipsicon"> </span>
                      <div class="win-my-tips2">{{ $t('win3.收益率=期末净值/账号累积入金') }}</div>
                    </div>
                  </div>
                  <div class="td">{{ $t('win3.奖金') }}</div>
                  <div class="td">{{ $t('win3.跟单') }}</div>
                </div>
                <div class="no-data">{{ $t('win3.暂未开赛') }}</div>
              </div>
            </div>
          </div>

          <div class="section4-tips" v-html="$t('win3.该页面仅展示收益表现排名前10的交易账号')">
          </div>
        </section>

        <section class="section5">
          <img src="@/assets/image/win/section5_m.png" alt="" class="section5-2025" />
          <h3 class="tab-title  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.赛事进程') }}</h3>
          <div class="section5-time-line">
            <div class="section5-lines">
              <div class="section5-line-item">
                <p class="p1">{{ $t('win3.2025年2月22日') }}</p>
                <i class="iconfont icon-Vector"></i>
                <p class="p2">{{ $t('win3.推荐开始') }}</p>
              </div>
              <div class="section5-line-item">
                <p class="p1">{{ $t('win3.2025年3月1日') }}</p>
                <i class="iconfont icon-Vector"></i>
                <p class="p2">{{ $t('win3.比赛开始') }}</p>
              </div>
              <div class="section5-line-item">
                <p class="p1">{{ $t('win3.2025年5月23日') }}</p>
                <i class="iconfont icon-Vector"></i>
                <p class="p2">{{ $t('win3.推荐结束') }}</p>
              </div>
              <div class="section5-line-item">
                <p class="p1">{{ $t('win3.2025年5月30日') }}</p>
                <i class="iconfont icon-Vector"></i>
                <p class="p2">{{ $t('win3.比赛结束') }}</p>
              </div>
              <div class="section5-line-item">
                <p class="p1">{{ $t('win3.2025年') }}</p>
                <i class="iconfont icon-Vector"></i>
                <p class="p2">{{ $t('win3.颁奖典礼') }}</p>
              </div>
            </div>
          </div>
          <div class="buttons mt-56">
            <a :href="signupUrl" class="section-button" target="_blank">{{ $t('win3.立即参赛') }}</a>
          </div>
        </section>
        <section class="section6">
          <div class="tab-btns">
            <div id="section6button1" @click="handleChangeZu(true)" class="button-title "
              :class="[{ 'button-title-active': section6ContentFlag }, $t('common.fontFamilyTitle')]">
              {{ $t('win3.赛组规则') }}
            </div>
            <div class="section6-line"></div>
            <div id="section6button2" @click="handleChangeZu(false)" class="button-title  "
              :class="[{ 'button-title-active': !section6ContentFlag }, $t('common.fontFamilyTitle')]">
              {{ $t('win3.参赛须知') }}
            </div>
          </div>
          <div v-show="section6ContentFlag" class="section6-content">
            <div class="pc">
              <table class="section6-table">
                <thead>
                  <tr>
                    <th> {{ $t('win3.类别') }} </th>
                    <th>{{ $t('win3.梦之队') }}</th>
                    <th>{{ $t('win3.青训营') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t('win3.账号要求') }}</td>
                    <td v-html="$t('win3.新交易账号，且$10,000≤账号余额≤$200,000')"></td>
                    <td>{{ $t('win3.新交易账号，且账号余额≥$500') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('win3.排名规则') }}</td>
                    <td>
                      {{ $t('win3.以收益额排名为评判标准') }}<br />
                      {{ $t('win3.收益额=期末净值+出金-入金') }}
                    </td>
                    <td>
                      {{ $t('win3.以收益率排名为评判标准') }}<br />
                      {{ $t('win3.收益率=期末净值/账号累积入金') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('win3.持仓限制') }}</td>
                    <td v-html="$t('win3.每个参赛账号最多持有100手仓位')"></td>
                    <td>{{ $t('win3.无持仓限制') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mobile">
              <table class="section6-table">
                <thead>
                  <tr>
                    <th> {{ $t('win3.类别') }} </th>
                    <th>{{ $t('win3.梦之队') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t('win3.账号要求') }}</td>
                    <td v-html="$t('win3.新交易账号，且$10,000≤账号余额≤$200,000')"></td>
                  </tr>
                  <tr>
                    <td>{{ $t('win3.排名规则') }}</td>
                    <td>
                      {{ $t('win3.以收益额排名为评判标准') }}<br />
                      {{ $t('win3.收益额=期末净值+出金-入金') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('win3.持仓限制') }}</td>
                    <td v-html="$t('win3.每个参赛账号最多持有100手仓位')"></td>
                  </tr>
                </tbody>
              </table>
              <table class="section6-table">
                <thead>
                  <tr>
                    <th> {{ $t('win3.类别') }} </th>
                    <th>{{ $t('win3.青训营') }}</th>
                  </tr>

                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t('win3.账号要求') }}</td>
                    <td>{{ $t('win3.新交易账号，且账号余额≥$500') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('win3.排名规则') }}</td>
                    <td>
                      {{ $t('win3.以收益率排名为评判标准') }}<br />
                      {{ $t('win3.收益率=期末净值/账号累积入金') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('win3.持仓限制') }}</td>
                    <td>{{ $t('win3.无持仓限制') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>


            <div class="section6-step-box">
              <h3 class="section6-step-title  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.参赛流程')
              }}</h3>
              <div class="section6-step-content">
                <div class="section6-step-item section6-item-1">
                  <h5>{{ $t('win3.注册开户') }}</h5>
                  <p class="p2">{{ $t('win3.注册并开设新交易账号') }}</p>
                </div>
                <img class="section6-right" src="@/assets/image/win/section6_right.png" alt="" />
                <div class="section6-step-item section6-item-2">
                  <h5>{{ $t('win3.报名参赛') }}</h5>
                  <p class="p2">{{ $t('win3.在用户中心报名参赛') }}</p>
                </div>
                <img class="section6-right" src="@/assets/image/win/section6_right.png" alt="" />
                <div class="section6-step-item section6-item-3">
                  <h5>{{ $t('win3.参赛成功') }}</h5>
                  <p class="p2">{{ $t('win3.参赛成功查看实时赛况') }}</p>
                </div>
              </div>
              <div class="buttons">
                <a :href="signupUrl" class="section-button" target="_blank">{{ $t('win3.立即参赛') }}</a>
              </div>
            </div>
          </div>
          <div v-show="!section6ContentFlag" class="section6-content">
            <div class="section6-list">
              <div class="section6-list-title">{{ $t('win3.参赛条款') }}</div>
              <div class="section6-list-item" v-for="(item, index) in $t('win3.section6List')" :key="index">
                <div class="section6-list-item-title">{{ item.t }}</div>
                <div class="section6-list-item-content">
                  <p v-for="(p, i) in item.c" :key="i">{{ p }}</p>
                </div>
              </div>
            </div>

            <div class="qa-list mt-12">
              <div class="qa-list-title">{{ $t('win3.常见问题') }}</div>
              <div class="qa-list-item" v-for="(item, index) in $t('win3.qaList')" :key="index">
                <div class="qa-list-item-title">{{ item.t }}</div>
                <div class="qa-list-item-content">
                  <p v-for="(p, i) in item.c" :key="i">{{ p }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section8">
          <h3 class="tab-title  " :class="$t('common.fontFamilyTitle')">{{ $t('win3.往届回顾') }}</h3>
          <div style="position: relative">
            <img class="section8-bg-top" src="@/assets/image/win/section8_top.png" draggable="false" alt="" />
            <div class="section8-content">
              <swiper ref="section8-content" :options="swiperSection8Options">
                <swiper-slide>
                  <div class="section8-item">
                    <img class="section8-item-img" src="@/assets/image/win/section8_1.jpg" alt="" />
                    <div class="svg-play-button" @click="videoFlag1 = true"></div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="section8-item">
                    <img class="section8-item-img" src="@/assets/image/win/section8_2.jpg" alt="" />
                    <div class="svg-play-button" @click="videoFlag2 = true"></div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="section8-item">
                    <img class="section8-item-img" src="@/assets/image/win/section8_3.jpg?v=1.3.3" alt="" />
                    <div class="svg-play-button" @click="videoFlag3 = true"></div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <img class="section8-bg-bottom" src="@/assets/image/win/section8_bottom.png" draggable="false" alt="" />
          </div>

          <div class="buttons mt-56">
            <a href="/win1" class="section-button">{{ $t('win3.查看更多') }}</a>
          </div>
          <div v-if="videoFlag1" class="cr-modal">
            <div class="cr-modal-content">
              <span class="cr-close" @click="videoFlag1 = false">&times;</span>
              <video controls>
                <source src="https://ebconline.asia/videos/video1.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          <div v-if="videoFlag2" class="cr-modal">
            <div class="cr-modal-content">
              <span class="cr-close" @click="videoFlag2 = false">&times;</span>
              <video controls>
                <source src="https://ebconline.asia/videos/video2.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          <div v-if="videoFlag3" class="cr-modal">
            <div class="cr-modal-content">
              <span class="cr-close" @click="videoFlag3 = false">&times;</span>
              <video controls>
                <source src="https://ebconline.asia/videos/video3.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </section>
        <div class="section1-info-time">
          <div class="section1-info-time-label pc">{{ $t('win3.距开赛') }}</div>
          <div class="section1-info-opentime">
            <div class="section1-info-time-label mobile">{{ $t('win3.距开赛') }}</div>
            <span id="day1" class="number-box">0</span>
            <span id="day2" class="number-box">0</span>
            {{ $t('win3.天') }}
            <span id="hour1" class="number-box">0</span>
            <span id="hour2" class="number-box">0</span>
            {{ $t('win3.时') }}
            <span id="minute1" class="number-box">0</span>
            <span id="minute2" class="number-box">0</span>
            {{ $t('win3.分') }}
            <span id="second1" class="number-box">0</span>
            <span id="second2" class="number-box">0</span>
            {{ $t('win3.秒') }}
          </div>
          <div class="buttons">
            <a :href="signupUrl" class="section-button time-button" target="_blank">{{ $t('win3.立即参赛')
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <page-footer></page-footer>
  </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

// 获取2位数组
function splitTensAndOnes(num) {
  // 确保输入的是非负整数
  if (!Number.isInteger(num) || num < 0) {
    throw new Error("请输入一个非负整数");
  }
  // 将数字转换为字符串，以便容易地访问每一位
  let strNum = String(num);
  // 如果数字大于等于100，我们只取最后两位
  if (num >= 100) {
    strNum = strNum.slice(-2);
  }
  // 补齐到两位，如果需要的话
  if (strNum.length < 2) {
    strNum = "0" + strNum;
  }
  // 分割字符串为数组并转换回数字
  const result = Array.from(strNum, Number);

  return result;
}

let startDateB = new Date("2025-03-01T07:00:00+08:00"); // 北京时间2025-03-01 07:00:00
let endDateB = new Date("2025-05-31T04:59:59+08:00"); // 北京时间2025-05-31 04:59:59
const startDate = new Date(
  startDateB.toLocaleString("en-US", { timeZone: "Asia/Shanghai" })
);
const endDate = new Date(
  endDateB.toLocaleString("en-US", { timeZone: "Asia/Shanghai" })
);


// 节流函数
function throttle(fn, wait) {
  console.log("throttle");
  let lastTime = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastTime >= wait) {
      fn.apply(this, args);
      lastTime = now;
    }
  };
}

import { mapGetters } from 'vuex';
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      videoFlag1: false,
      videoFlag2: false,
      videoFlag3: false,
      section2ContentFlag: true,
      section4ContentFlag: true,
      section6ContentFlag: true,
      signupUrl: "https://client.myebc.co/signup-c/",
      datalist: [],
      datalist2: [],
      swiperRewardOptions: {
        speed: 5000,
        slidesPerView: 2.5,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
      },
      swiperReward2Options: {
        speed: 5000,
        slidesPerView: 2.5,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
      },
      swiperSection2bOptions: {
        slidesPerView: 1.9,
        autoplay: true,
        loop: true,
        centeredSlides: true,
        spaceBetween: 80,
      },
      swiperSection4ListOptions: {
        slidesPerView: "auto", // 根据内容自适应宽度
        spaceBetween: 10, // 幻灯片之间的间距
      },
      swiperSection4List2Options: {
        slidesPerView: "auto", // 根据内容自适应宽度
        spaceBetween: 10, // 幻灯片之间的间距
      },
      swiperSection8Options: {
        slidesPerView: 1.9,
        autoplay: true,
        loop: true,
        centeredSlides: true,
        spaceBetween: 0,
      },
      rankValueList: this.$t("win3.rankValueList"),
      rankValueList2: this.$t("win3.rankValueList2"),
      timer: undefined
    }
  },
  // computed: {
  //     ...mapGetters([
  //         'signupUrl',
  //     ])
  // },
  methods: {
    handleChange(flag) {
      this.section2ContentFlag = flag
      this.$nextTick(() => {
        const element = document.querySelectorAll(".reward")[0];
        element.classList.remove("reward-pc-show");
        setTimeout(() => {
          element.classList.add("reward-pc-show");
        })
      })

    },

    handleChangeBang(flag) {
      this.section4ContentFlag = flag
      this.createdData(flag ? "2" : "1");
    },

    handleChangeZu(flag) {
      this.section6ContentFlag = flag
    },
    createdData(group) {
      //
      // https://api.appsuat.com/crm/openApi/win2025/queryAllWin2025List
      fetch(
        "https://api.eappback.com/crm/openApi/win2025/queryAllWin2025List",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            contestGroup: group,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) throw new Error("Network response was not ok");
          return response.json();
        })
        .then((data) => {
          console.log("queryAllWin2025List", data);
          if (data && data.code == 200) {
            if (group == 2) {
              let result = data.data
                .sort((item1, item2) => {
                  return item1.rank - item2.rank;
                })
                .map((el) => {
                  return {
                    id: el.uuid,
                    Rank: el.rank,
                    Nickname: el.nickName,
                    Server: el.serverName,
                    AccountNumber: el.account,
                    Profit: el.profit.toFixed(2),
                    Prize: this.rankValueList[el.rank - 1],
                    preRank:
                      el.account == "6425345"
                        ? this.$t('win3.第一届大赛亚军')
                        : el.account == "1323672"
                          ? this.$t('win3.第一届大赛季军')
                          : "",
                  };
                });
              console.log("result", result)
              this.datalist = result;

            }
            if (group == 1) {
              let result = data.data
                .sort((item1, item2) => {
                  return item1.rank - item2.rank;
                })
                .map((el) => {
                  return {
                    id: el.uuid,
                    Rank: el.rank,
                    Nickname: el.nickName,
                    Server: el.serverName,
                    AccountNumber: el.account,
                    ProfitRate: (el.yields * 100).toFixed(2) + "%",
                    Prize: this.rankValueList2[el.rank - 1],
                    preRank:
                      el.account == "6425345"
                        ? this.$t('win3.第一届大赛亚军')
                        : el.account == "1323672"
                          ? this.$t('win3.第一届大赛季军')
                          : "",
                  };
                });
              console.log("result", result)
              this.datalist2 = result;

            }
          }
        })
        .catch((error) =>
          console.error("There was a problem with the fetch operation:", error)
        );
    },
    updateCountdown() {
      const now = new Date(); // 当前时间
      // 将当前时间转换为北京时区的时间
      const nowInBeijing = new Date(
        now.toLocaleString("en-US", { timeZone: "Asia/Shanghai" })
      );
      if (startDate > nowInBeijing) {
        const difference = startDate - nowInBeijing;
        const days = splitTensAndOnes(
          Math.floor(difference / (1000 * 60 * 60 * 24))
        );
        const hours = splitTensAndOnes(
          Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        const minutes = splitTensAndOnes(
          Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        );
        const seconds = splitTensAndOnes(
          Math.floor((difference % (1000 * 60)) / 1000)
        );

        document.getElementById("day1").textContent = days[0];
        document.getElementById("day2").textContent = days[1];
        document.getElementById("hour1").textContent = hours[0];
        document.getElementById("hour2").textContent = hours[1];
        document.getElementById("minute1").textContent = minutes[0];
        document.getElementById("minute2").textContent = minutes[1];
        document.getElementById("second1").textContent = seconds[0];
        document.getElementById("second2").textContent = seconds[1];
      } else {
        const difference = endDate - nowInBeijing;
        const days = splitTensAndOnes(
          Math.floor(difference / (1000 * 60 * 60 * 24))
        );
        const hours = splitTensAndOnes(
          Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        const minutes = splitTensAndOnes(
          Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        );
        const seconds = splitTensAndOnes(
          Math.floor((difference % (1000 * 60)) / 1000)
        );

        document.getElementById("day1").textContent = days[0];
        document.getElementById("day2").textContent = days[1];
        document.getElementById("hour1").textContent = hours[0];
        document.getElementById("hour2").textContent = hours[1];
        document.getElementById("minute1").textContent = minutes[0];
        document.getElementById("minute2").textContent = minutes[1];
        document.getElementById("second1").textContent = seconds[0];
        document.getElementById("second2").textContent = seconds[1];
        try {
          const timeLabels = document.querySelectorAll(".section1-info-time-label");
          Array.from(timeLabels).forEach((label) => {
            label.textContent = this.$t("win3.距结束");
          });
        } catch (e) { }
      }
    }

  },
  mounted() {
    // 获取所有 qa-list-item 元素
    const items = document.querySelectorAll(".qa-list-item");

    // 遍历所有 qa-list-item 并为它们添加点击事件监听器
    items.forEach((item) => {
      item.addEventListener("click", function () {
        // 如果当前点击的是已经拥有 active 类的元素，则移除它
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        } else {
          // 否则先移除所有 qa-list-item 的 active 类，然后给当前点击的元素添加 active 类
          items.forEach((i) => i.classList.remove("active"));
          item.classList.add("active");
        }
      });
    });
    this.updateCountdown();
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(() => {
      this.updateCountdown();
    }, 1000);
    this.createdData(2)
    // 滚动进入动画
    document.addEventListener(
      "scroll",
      throttle(function () {

        const element = document.querySelectorAll(".reward")[0];
        // 获取元素相对于视口的位置
        const rect1 = element.getBoundingClientRect();
        // 判断元素是否进入了视口范围内
        if (rect1.top <= window.innerHeight && rect1.bottom >= 0) {
          // 当元素进入视口范围内时，添加'show'类

          element.classList.add("reward-pc-show");
        } else {
          element.classList.remove("reward-pc-show");
        }

        const element3 = document.querySelectorAll(".section2f-card-box")[0];
        // 获取元素相对于视口的位置
        const rect4 = element3.getBoundingClientRect();
        // 判断元素是否进入了视口范围内
        if (rect4.top <= window.innerHeight && rect4.bottom >= 0) {
          // 当元素进入视口范围内时，添加'show'类

          element3.classList.add("show");
        } else {
          element3.classList.remove("show");
        }


        const element2 = document.querySelectorAll(".section3")[0];

        // 获取元素相对于视口的位置
        const rect = element2.getBoundingClientRect();

        // 判断元素是否进入了视口范围内
        if (rect.top + 100 <= window.innerHeight && rect.bottom >= 0) {
          // 当元素进入视口范围内时，添加'show'类

          element2.classList.add("show");
        } else {
          element2.classList.remove("show");
        }


        const jiangbei_banner = document.querySelectorAll(".jiangbei_banner")[0];

        // 获取元素相对于视口的位置
        const rect3 = jiangbei_banner.getBoundingClientRect();
        const section1 = document.querySelectorAll(".section1")[0];
        // 判断元素是否进入了视口范围内
        if (rect3.top <= window.innerHeight && rect3.bottom >= 0) {
          // 当元素进入视口范围内时，添加'show'类

          section1.classList.add("show");
        } else {
          section1.classList.remove("show");
        }
      }, 300)
    );

    document.addEventListener("DOMContentLoaded", function () {
      var imgElements = document.getElementsByClassName("navigate-logo-img");
      imgElements[0].src = "../assets/image/navigate/logo_light.svg";

    });

  },
  beforeDestroy() {
    // 当组件即将被销毁前，清除定时器
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .swiper-pagination {
  transform: translateY(80rem) !important;
}

::v-deep .swiper-pagination-bullet {
  background: #a52933 !important;
  opacity: 0.5 !important;
}

::v-deep .swiper-pagination-bullet-active {
  background: #a52933 !important;
  opacity: 1 !important;
}

.section2b-content-m .swiper-container {
  overflow: visible;
}

@font-face {
  font-family: "Financier Display";
  src: url("../assets/font/FinancierDisplay-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Financier Display Regular";
  src: url("../assets/font/FinancierDisplay-Regular.otf") format("opentype");
}

.en_US .en-font-family-title {
  font-family: "Financier Display" !important;
}

.en_US .reward-item .p1,
.en_US .reward-item-m .p1 {
  font-family: "Financier Display" !important;
}

/* 
.en_US .section-button,
.en_US .buttons-group {
  font-family: "Financier Display" !important;
}  */
.section4-table .tr .td:nth-child(4) {
  text-align: end;
  padding-right: 75rem;
}

.body-bg {
  background-color: #000;
  background-image: url("../assets/image/win/bg.png");
  background-size: 100% auto;
  background-repeat: repeat-y;
}

.section1 {
  position: relative;
  height: 860rem;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 70rem; */
  justify-content: flex-end;
  align-items: center;
  background: url("../assets/image/win/section1_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.logo_banner {
  width: 337rem;
  height: 55rem;
}

.jiangbei_banner {
  width: 516rem;
  height: 546rem;
  margin-top: -20rem;
}

.show .jiangbei_banner {
  animation-name: jbshow;
  animation-duration: 1.2s;
}

@keyframes jbshow {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.b_left {
  position: absolute;
  width: 623rem;
  height: 296rem;
  left: 450rem;
  bottom: 166rem;
  z-index: 3;
}

.show .b_left {
  animation-name: b_leftshow;
  animation-duration: 1.2s;
}

@keyframes b_leftshow {
  0% {
    transform: translate(-300rem);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.b_right {
  position: absolute;
  width: 403rem;
  height: 372rem;
  left: 1000rem;
  bottom: 166rem;
  z-index: 2;
}

.show .b_right {
  animation-name: b_rightshow;
  animation-duration: 1.2s;
}

@keyframes b_rightshow {
  0% {
    transform: translate(400rem);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.section1-title-box {
  padding: 0 50rem 20rem 50rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 994rem;
  height: 166rem;
  background: url("../assets/image/win/title_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.en .section1-title-box {
  padding-bottom: 0;
  gap: 20rem;
}

.show .section1-title-box {
  /* animation-name: section1-title-boxshow;
  animation-duration: 1s; */
}

@keyframes section1-title-boxshow {
  0% {
    transform: translateY(100rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.section1 h1 {
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  text-shadow: 0rem 3.415rem 2.277rem rgba(0, 0, 0, 0.45);
  font-size: 55rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.558rem;
  gap: 4rem;
}

.en .section1 h1 {
  font-size: 48rem;
}

.th .section1 h1 {
  font-size: 34rem;
}

.section1 h1 .ebc {
  font-size: 78.447rem;
  letter-spacing: 0.784rem;
  font-family: "Financier Display";
}

.section1-intro {
  margin: 40rem auto 0;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 0 25rem;
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 29rem;
  max-width: 1000rem;
}

.subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12rem;
  color: #fff;
  text-align: center;
  font-size: 32rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.48rem;
  opacity: 0.7;
}

.en .subtitle {
  font-size: 16rem;
  font-weight: 500;
}

.title-icon {
  width: 28rem;
  height: 28rem;
}

.subtitle-line-left {
  height: 1px;
  width: 130rem;
  background: linear-gradient(to left,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0));
}

.ar .subtitle-line-left {
  height: 1px;
  width: 150rem;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0));
}

.subtitle .icon-Vector {
  font-size: 24rem;
}

.subtitle-line-right {
  height: 1px;
  width: 130rem;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0));
}

.en .subtitle-line-left,
.en .subtitle-line-right {
  width: 100rem;
}

.ar .subtitle-line-right {
  height: 1px;
  width: 180rem;
  background: linear-gradient(to left,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0));
}

.bg {
  background: url("../assets/image/win/bg.png");
  background-size: 100% auto;
  background-repeat: repeat;
}

.section1-info-time {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 34rem;
  background: rgba(0, 0, 0, 0.75);
  z-index: 9998;
}

.section1-info-time-label {
  color: #fff;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 18rem;
  /* 128.571% */
  margin-right: -18rem;
}

.section1-info-times {
  text-align: center;
  color: #b2afad;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  margin-top: 58rem;
}

.section1-info-opentime {
  display: flex;
  align-items: center;
  gap: 12rem;
  color: #fff;
  text-align: center;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 24rem;
}

.section-button {
  padding: 18rem 36rem 20rem;
  background: linear-gradient(180deg, #9b2143 0%, #5d2333 82.5%);
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 24rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  transition: all 0.3s;
  animation: scaleAnimation 2s infinite;
}

.time-button {
  padding: 16rem 36rem 18rem;
  font-size: 20rem;
  animation: none;
}

.time-button:hover {
  transform: scale(1.04);
}

.section-button:hover {
  color: rgba(255, 255, 255, 0.8);
  /* transform: scale(1.1); */
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
    /* 初始状态，原始大小 */
  }

  37.5% {
    /* 在前0.75秒内放大到1.1倍 */
    transform: scale(1.1);
  }

  50% {
    /* 静止0.25秒 */
    transform: scale(1.1);
  }

  87.5% {
    /* 接下来的0.75秒内缩小回原始大小 */
    transform: scale(1);
  }

  100% {
    /* 再静止0.25秒 */
    transform: scale(1);
  }
}

.section2,
.section4,
.section6 {
  position: relative;
  padding: 90rem 0 100rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: linear-gradient(
      180deg,
      rgb(255, 255, 255, 0.95) 0%,
      rgba(178, 175, 173, 0.99) 100%
    ),
    url("../assets/image/win/bg.png");
  background-size: 100% auto;
  background-repeat: repeat; */
}

.section-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.section8 {
  position: relative;
  padding: 90rem 0 100rem 0;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 1) 90rem,
      rgba(0, 0, 0, 1) 100%);
  /* background: linear-gradient(
      180deg,
      rgba(93, 35, 51, 0.95) 0%,
      rgba(43, 10, 10, 0.99) 100%
    ),
    url("../assets/image/win/bg.png");
  background-size: 100% auto;
  background-repeat: repeat; */
}

/* .section2 {
  padding-bottom: 0;
} */
.section2-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20rem;
}

.section2-card-label {
  color: #fff;
  text-align: center;
  font-size: 40rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.6rem;
}

.jiangjin {
  width: 702rem;
  height: 158rem;
}

.section2-card-value {
  text-align: center;
  font-family: "Financier Display";
  font-size: 170rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 6.8rem;
  background: linear-gradient(180deg, #fff 20.5%, rgba(153, 153, 153, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section2-intro {
  margin: 26rem auto 38rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
}

.section2b {
  margin-top: 40rem;
  padding: 100rem 0;
  height: 900rem;
  position: relative;
  overflow: hidden;
}

.section2f-vectors {
  display: none;
}

.section2b-vectors {
  display: flex;
  justify-content: center;
  gap: 10rem;
  margin: 26rem auto;
}

.section2b-vectors .icon-Vector {
  font-size: 32rem;
}

.section2b-intro {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 1000rem;
  margin: 0 auto;
}

.section2b-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.section2b-content {
  display: flex;
  justify-content: center;
  gap: 21rem;
  margin-top: 60rem;
}

.section2b-item {
  position: relative;
  display: flex;
  width: 380rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  background: rgba(0, 0, 0, 0.49);
  transition: all 0.3s;
  border-left: 1px solid #a52933;
  border-right: 1px solid #114291;
}

.section2b-line {
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #a52933, #114291);
}

.section2b-item:hover {
  transform: scale(1.05);
}

.section2b-item-img {
  width: 380rem;
  height: 254rem;
}

.section2b-item h4 {
  padding: 0 16rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 18rem;
}

.section2b-item p {
  padding: 0 16rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 20rem;
  margin-bottom: 28rem;
  /* line-height: 1em; */
  flex: 1;
}

.reward-list-pc {
  display: flex;
  justify-content: center;
  gap: 10rem;
}

.reward-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
  /* width: 282rem;
  height: 442rem; */
}

.reward-item-1 {
  width: 260rem;
}

.reward-item-1 .reward-item-jiangbei {
  width: 260rem;
  height: 301rem;
  background-image: url("../assets/image/win/jiangbei_s_1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-item-1 .reward-item-content {
  display: none;
  width: 260rem;
  height: 164rem;
  background-image: url("../assets/image/win/jiangbei_content_1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* .reward-pc-show:hover .reward-item-jiangbei,
.reward-pc-show:hover .reward-item-content {
  animation-play-state: paused;
} */
.reward-pc-show .reward-item-1 .reward-item-jiangbei {
  background-image: url("../assets/image/win/jiangbei_e_1.png");
  animation-name: uptojiangbei1;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
  transform: translateY(10rem);
  z-index: 1;
}

.reward-pc-show .reward-item-1 .reward-item-content {
  display: flex;
  animation-name: upto1;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
}

@keyframes uptojiangbei1 {
  0% {
    background-image: url("../assets/image/win/jiangbei_s_1.png");
    opacity: 1;
    transform: translateY(174rem);
  }

  40% {
    background-image: url("../assets/image/win/jiangbei_e_1.png");
    transform: translateY(10rem);
  }

  100% {
    background-image: url("../assets/image/win/jiangbei_e_1.png");
    transform: translateY(10rem);
  }
}

@keyframes upto1 {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(164rem);
  }

  40% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

.reward-item-2 {
  width: 240rem;
}

.reward-item-2 .reward-item-jiangbei {
  width: 240rem;
  height: 235rem;
  background-image: url("../assets/image/win/jiangbei_s_2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-item-2 .reward-item-content {
  display: none;
  width: 240rem;
  height: 140rem;
  background-image: url("../assets/image/win/jiangbei_content_2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-pc-show .reward-item-2 .reward-item-jiangbei {
  background-image: url("../assets/image/win/jiangbei_e_2.png");
  animation-name: uptojiangbei2;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
  transform: translateY(10rem);
  z-index: 1;
}

.reward-pc-show .reward-item-2 .reward-item-content {
  display: flex;
  animation-name: upto2;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
}

@keyframes uptojiangbei2 {
  0% {
    background-image: url("../assets/image/win/jiangbei_s_2.png");
    opacity: 1;
    transform: translateY(150rem);
  }

  40% {
    background-image: url("../assets/image/win/jiangbei_e_2.png");
    transform: translateY(10rem);
  }

  100% {
    background-image: url("../assets/image/win/jiangbei_e_2.png");
    transform: translateY(10rem);
  }
}

@keyframes upto2 {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(140rem);
  }

  40% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

.reward-item-3 {
  width: 240rem;
}

.reward-item-3 .reward-item-jiangbei {
  width: 240rem;
  height: 196rem;
  background-image: url("../assets/image/win/jiangbei_s_3.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-item-3 .reward-item-content {
  display: none;
  width: 240rem;
  height: 140rem;
  background-image: url("../assets/image/win/jiangbei_content_2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-pc-show .reward-item-3 .reward-item-jiangbei {
  background-image: url("../assets/image/win/jiangbei_e_3.png");
  animation-name: uptojiangbei3;
  animation-duration: 4s;
  transform: translateY(10rem);
  /* animation-iteration-count: infinite; */
  z-index: 1;
}

.reward-pc-show .reward-item-3 .reward-item-content {
  display: flex;
  animation-name: upto3;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
}

@keyframes uptojiangbei3 {
  0% {
    background-image: url("../assets/image/win/jiangbei_s_3.png");
    opacity: 1;
    transform: translateY(150rem);
  }

  40% {
    background-image: url("../assets/image/win/jiangbei_e_3.png");
    transform: translateY(10rem);
  }

  100% {
    background-image: url("../assets/image/win/jiangbei_e_3.png");
    transform: translateY(10rem);
  }
}

@keyframes upto3 {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(140rem);
  }

  40% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

.reward-item-4 {
  width: 200rem;
}

.reward-item-4 .reward-item-jiangbei {
  width: 200rem;
  height: 147rem;
  background-image: url("../assets/image/win/jiangbei_s_4.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-item-4 .reward-item-content {
  display: none;
  width: 200rem;
  height: 124rem;
  background-image: url("../assets/image/win/jiangbei_content_3.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-pc-show .reward-item-4 .reward-item-jiangbei {
  background-image: url("../assets/image/win/jiangbei_e_4.png");
  animation-name: uptojiangbei4;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
  transform: translateY(10rem);

  z-index: 1;
}

.reward-pc-show .reward-item-4 .reward-item-content {
  display: flex;
  animation-name: upto4;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
}

@keyframes uptojiangbei4 {
  0% {
    background-image: url("../assets/image/win/jiangbei_s_4.png");
    opacity: 1;
    transform: translateY(134rem);
  }

  40% {
    background-image: url("../assets/image/win/jiangbei_e_4.png");
    transform: translateY(10rem);
  }

  100% {
    background-image: url("../assets/image/win/jiangbei_e_4.png");
    transform: translateY(10rem);
  }
}

@keyframes upto4 {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(124rem);
  }

  40% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

.reward-item-5 {
  width: 200rem;
}

.reward-item-5 .reward-item-jiangbei {
  width: 200rem;
  height: 134rem;
  background-image: url("../assets/image/win/jiangbei_s_5.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-item-5 .reward-item-content {
  display: none;
  width: 200rem;
  height: 124rem;
  background-image: url("../assets/image/win/jiangbei_content_3.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.reward-pc-show .reward-item-5 .reward-item-jiangbei {
  background-image: url("../assets/image/win/jiangbei_e_5.png");
  animation-name: uptojiangbei5;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
  transform: translateY(10rem);
  z-index: 1;
}

.reward-pc-show .reward-item-5 .reward-item-content {
  display: flex;
  animation-name: upto5;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
}

@keyframes uptojiangbei5 {
  0% {
    background-image: url("../assets/image/win/jiangbei_s_5.png");
    opacity: 1;
    transform: translateY(134rem);
  }

  40% {
    background-image: url("../assets/image/win/jiangbei_e_5.png");
    transform: translateY(10rem);
  }

  100% {
    background-image: url("../assets/image/win/jiangbei_e_5.png");
    transform: translateY(10rem);
  }
}

@keyframes upto5 {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(124rem);
  }

  40% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

.reward-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12rem;
  width: 100%;
  padding: 0 20rem;
  height: 151rem;
}

.reward-item .p1,
.reward-item-m .p1 {
  color: #fff;
  text-align: center;
  font-size: 32rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
}

.en .reward-item .p1,
.en .reward-item-m .p1 {
  font-size: 22rem;
}

.reward-item .p2,
.reward-item-m .p2 {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 21rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
}

.en .reward-item .p2,
.en .reward-item-m .p2 {
  font-size: 18rem;
  font-weight: 400;
}

.section2f {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90rem 0;
  /* background: url("../assets/image/win/section2f_bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
}

.section2f-card-box {
  margin-top: 50rem;
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.section2f-card {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 20rem;
  height: 340rem;
  width: 294rem;
  padding-top: 210rem;
  margin-top: 30rem;
}

.show .section2f-card {
  animation: rotateAnimation 8s;
}

/* .section2f-card:hover {
  transition: transform 1s ease-in-out;
  transform: rotateY(360deg);
} */

@keyframes rotateAnimation {
  0% {
    transform: rotateY(0deg);
  }

  20% {
    transform: rotateY(360deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.section2f-card-box .section2f-card:nth-child(1) {
  background: url("../assets/image/win/section2f_item_bg_1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.section2f-card-box .section2f-card:nth-child(2) {
  background: url("../assets/image/win/section2f_item_bg_2.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.section2f-card-box .section2f-card:nth-child(3) {
  background: url("../assets/image/win/section2f_item_bg_3.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.section2f-card-label {
  color: #6a6967;
  font-size: 19rem;
  font-style: normal;
  font-weight: 700;
  line-height: 22rem;
}

.section2f-card-value {
  color: #a52933;
  font-family: "Financier Display";
  font-size: 105rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 3.15rem;
}

.section2f-card-icon {
  width: 27rem;
  height: 27rem;
  margin-top: 92rem;
}

.section2f-card .p1 {
  color: #fff;
  text-align: center;
  font-size: 32rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
}

.section2f-card .p2 {
  color: #c9c7c6;
  text-align: center;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}

.en .section2f-card {
  padding-top: 200rem;
  gap: 10rem;
}

.en .section2f-card .p1 {
  font-size: 28rem;
}

.en .section2f-card .p2 {
  font-size: 20rem;
  line-height: 1.1em;
  padding: 0 20rem;
  /* max-width: 66%; */
}

.tab-title {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-size: 40rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.96rem;
}

.tab-title-red {
  color: #5d2333;
}

.reward {
  display: flex;
  gap: 12rem;

  width: 1450rem;
  height: 444rem;
  margin-top: 78rem;
}

.hidden {
  display: none;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  margin: 0;
}

.number-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: 41rem;
  border-radius: 5rem;
  border: 1px solid #6a6967;
  background: #000;
  color: #fff;
  text-align: center;
  font-family: "Financier Display";
  font-size: 32rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1em;
}

.buttons-group {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
  margin: 0 25rem;
}

.buttons-group>a {
  min-width: 300rem;
}

.section2-content {
  position: relative;
  /* overflow: hidden; */
  z-index: 2;
}

.group-button-left {
  padding: 18rem 36rem 20rem;
  background: linear-gradient(180deg, #94928e 0%, #4c4b49 85.5%);
  color: rgba(255, 255, 255, 0.43);
  text-align: center;
  font-size: 24rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  transition: all 0.3s;
}

.group-button-right {
  padding: 18rem 36rem 20rem;
  background: linear-gradient(180deg, #94928e 0%, #4c4b49 85.5%);
  color: rgba(255, 255, 255, 0.43);
  text-align: center;
  font-size: 24rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  transition: all 0.3s;
}

.group-button-left:hover,
.group-button-right:hover {
  color: rgba(255, 255, 255, 0.43);
}

.group-button-active:hover {
  color: rgba(255, 255, 255, 0.8);
}

.group-button-active {
  background: linear-gradient(180deg, #9b2143 0%, #5d2333 82.5%);
  color: rgba(255, 255, 255, 0.8);
}

.section3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90rem 0 100rem 0;
  /* background: url(../assets/image/win/section3_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
}

.section3-content {
  margin-top: 90rem;
  display: flex;
  flex-direction: column;
  min-width: 720rem;
  /* justify-content: center; */
}

.section3-content .p1 {
  color: #807e7c;
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
}

.section3-group {
  display: flex;
  gap: 25rem;
  margin-top: 18rem;
}

.show .section3-group,
.show .section3-content .p1:nth-child(1) {
  animation-name: section3-groupshow;
  animation-duration: 1.2s;
}

@keyframes section3-groupshow {
  0% {
    opacity: 0;
    transform: translate(-300rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.section3-group-card {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 137rem;
  flex-shrink: 0;
  border: 1px solid #a52933;
  background: linear-gradient(180deg, #3d3c3c 0%, #161414 73.5%);
}

.en .section3-group-card {
  flex-direction: column;
}

.section3-group-card1 {
  width: 375rem;
}

.section3-group-card2 {
  width: 318rem;
}

.section3-group-card-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #a52933;
  padding: 10rem 16rem;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 19rem;
  font-style: normal;
  font-weight: 500;
  line-height: 22rem;
}

.en .section3-group-card-left {
  width: 100%;
}

.section3-group-card-right {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #a52933;
  /* background: #f9f6f2; */
  padding: 10rem 0 30rem;
  font-size: 96rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  flex: 1;
  text-align: center;
}

.section3-group-card-value {
  background: linear-gradient(180deg, #fff 0%, #353535 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section3-group-card-right .unit {
  color: #a52933;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section3-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18rem;
  min-height: 189rem;
  padding: 0rem 48rem;
  /* border-radius: 5rem; */
  border: 1px solid var(--02042, #a52933);
  background: var(--02041, linear-gradient(180deg, #3d3c3c 0%, #161414 73.5%));
}

.show .section3-card,
.show .section3-content .mt-46 {
  animation-name: section3-cardshow;
  animation-duration: 1.2s;
}

@keyframes section3-cardshow {
  0% {
    opacity: 0;
    transform: translate(300rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.section3-card-steps {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 28rem;
  /* margin-top: 16rem; */
}

.section3-card-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step-img {
  width: 106rem;
  /* height: 78rem; */
}

.right-img {
  margin-top: 30rem;
  height: 60rem;
  width: 31rem;
}

.ar .right-img {
  transform: rotate(180deg);
}

.section3-card-step-value {
  margin-top: 10rem;
  color: #fff;
  font-size: 32rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
}

.en .section3-card-step-value {
  font-size: 24rem;
}

.times {
  margin-top: 16rem;
  color: #b2afad;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 8rem;
}

.mt-12 {
  margin-top: 12rem;
}

.mt-46 {
  margin-top: 46rem;
}

.mt-56 {
  margin-top: 56rem;
}

.mt-80 {
  margin-top: 80rem;
}

.section4-content {
  font-family: Roboto;
}

.section4-table {
  margin: 32rem auto 0;
  width: 1380rem;
}

.section4-table .tr {
  display: flex;
  align-items: center;
  padding: 24rem 50rem;
  background: linear-gradient(110deg, #474646 12.57%, #2a2828 86.07%);
}

.section4-table .th {
  display: flex;
  align-items: center;
  padding: 24rem 50rem;
  background: #232222;
}

.no-data {
  width: 100%;
  height: 310rem;
  background-color: #2a2828;
  background-image: url(../assets/image/win/no-data.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 286rem;
  font-size: 18rem;
  color: #6a6967;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section4-table .tr:hover {
  background: #b2afad;
}

.section4-table .td {
  width: 14.28%;
  flex-shrink: 0;
  flex-grow: 1;
}

.section4-table .td:first-child {
  width: 10%;
}

.section4-table .tr .td {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section4-table .th .td {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap:6rem;
}

.section4-table .th .td .icon-a-tipsicon {
  cursor: pointer;
  font-size: 14rem;
}

.button-text,
.button-text:hover {
  color: #ff4b4e;
}

.section4-table .tr>div:nth-child(5) {
  color: #ff4b4e;
  font-weight: 500;
}

.tr+.tr {
  border-top: 1px solid #000;
}

.section4-tips {
  margin-top: 24rem;
  width: 1380rem;
  color: #6a6967;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section5 {
  position: relative;
  /* background: linear-gradient(180deg, #5d2333 0%, #2b0a0a 100%); */
  padding: 90rem 0 100rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section5-bg {
  margin-top: 58rem;
  width: 100%;
  /* height: 395.5rem; */
}

.section5-time-line {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 294rem;
  background-image: url(../assets/image/win/section5.png);
  background-size: cover;
  margin-top: 52rem;
}

.section5-lines {
  /* margin: 0 180rem; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 384rem;
}

.section5-line-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20rem;
  width: 144rem;
  min-height: 204rem;
  padding: 20rem;
  border: 1px solid #a52933;
  background: linear-gradient(180deg, #3d3c3c 0%, #161414 73.5%);
}

.en .section5-line-item {
  width: 194rem;
}

.es .section5-line-item {
  justify-content: flex-start;
  padding: 40rem 20rem 20rem;
}

.section5-line-item .p1 {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Gilroy;
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
}

.section5-line-item .p2 {
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 24rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
}

.en .section5-line-item .p2 {
  font-size: 16rem;
}

.section5-line-item .icon-Vector {
  font-size: 40rem;
  color: #a52933;
  animation: rotateAnimation2 2s infinite;
}

/* .section5-line-item:hover .icon-Vector {
  transition: all 0.3s;
  transform: rotate(90deg);
} */

@keyframes rotateAnimation2 {
  0% {
    transform: rotate(0deg);
    color: #a52933;
  }

  37.5% {
    /* 0.75秒内从0度旋转到90度 */
    transform: rotate(90deg);
    color: #ff4b4e;
  }

  50% {
    /* 静止0.25秒 */
    transform: rotate(90deg);
    color: #ff4b4e;
  }

  87.5% {
    /* 接下来的0.75秒内从90度旋转回0度 */
    transform: rotate(0deg);
    color: #a52933;
  }

  100% {
    /* 再静止0.25秒 */
    transform: rotate(0deg);
    color: #a52933;
  }
}

.tab-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 68rem;
}

.section6-line {
  width: 1px;
  height: 50rem;
  background: #b2afad;
}

.section6-content {
  margin-top: 68rem;
}

.section6-table {
  width: 1032rem;
  border-spacing: 1px;
}

.section6-table th {
  padding: 26rem 30rem;
  background: #232222;
  color: rgba(255, 255, 255, 0.4);
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
  text-align: center;
}

.section6-table td {
  padding: 26rem 30rem;
  background: #474646;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: middle;
}

.section6-table td:first-child {
  /* color: #000; */
  /* font-size: 20rem; */
  font-weight: 500;
}

/* .section6-table th:last-child {
  background: #a52933;
} */

table {
  border-collapse: collapse;
  /* 合并边框 */
  border: none;
  /* 移除表格的外部边框 */
}

.section6-table td,
.section6-table th {
  text-align: center;
  border-bottom: 1px solid #000;
  /* 底边 */
  border-right: 1px solid #000;
  /* 右边 */
}

tr:last-child td {
  border-bottom: none;
  /* 移除最后一行的底边 */
}

tr th:last-child,
tr td:last-child {
  border-right: none;
  /* 移除每行最后一个单元格的右边 */
}

.section6-step-box {
  margin-top: 12rem;
  padding: 56rem 56rem 60rem 56rem;
  width: 1034rem;
  border: 1px solid var(--02042, #a52933);
  background: var(--02041, linear-gradient(180deg, #3d3c3c 0%, #161414 73.5%));
}

.section6-step-title {
  color: #fff;
  text-align: center;
  font-size: 32rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.64rem;
}

.section6-step-content {
  margin-top: 36rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24rem;
}

.section6-step-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24rem;
  width: 260rem;
  height: 260rem;
}

.section6-step-item h5 {
  color: #fff;
  text-align: center;
  font-size: 32rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  padding: 0 20rem;
}

.en .section6-step-item h5 {
  font-size: 20rem;
}

.section6-step-item .p2 {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  padding: 0 20rem;
}

.section6-item-1 {
  background: url(../assets/image/win/section6_step1.png);
  background-size: cover;
}

.section6-item-2 {
  background: url(../assets/image/win/section6_step2.png);
  background-size: cover;
}

.section6-item-3 {
  background: url(../assets/image/win/section6_step3.png);
  background-size: cover;
}

.section6-right {
  width: 23rem;
  height: 70rem;
}

.ar .section6-right {
  transform: rotate(180deg);
}

.section6-list {
  padding: 42rem 32rem 52rem;
  width: 782rem;
  border: 1px solid var(--02042, #a52933);
  background: var(--02041, linear-gradient(180deg, #3d3c3c 0%, #161414 73.5%));
}

.section6-list-title {
  color: #fff;
  text-align: center;
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 0.2rem;
  margin-bottom: 18rem;
}

.section6-list-item {
  padding-bottom: 14rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.section6-list-item+.section6-list-item {
  margin-top: 14rem;
}

.section6-list-item-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20rem;
}

.section6-list-item-content {
  margin-top: 10rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24rem;
}

.section6-list-item-content>p {
  position: relative;
  padding-left: 12rem;
}

.section6-list-item-content>p::before {
  position: absolute;
  left: 4rem;
  top: 11rem;
  content: "";
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #6d6d6d;
}

.qa-list {
  padding: 42rem 32rem 52rem;
  width: 782rem;
  border: 1px solid var(--02042, #a52933);
  background: var(--02041, linear-gradient(180deg, #3d3c3c 0%, #161414 73.5%));
}

.qa-list-title {
  color: #fff;
  text-align: center;
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 0.2rem;
  margin-bottom: 18rem;
}

.qa-list-item {
  padding-bottom: 14rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.qa-list-item+.qa-list-item {
  margin-top: 14rem;
}

.qa-list-item-title {
  position: relative;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20rem;
  padding-right: 28rem;
}

.qa-list-item-title::after {
  position: absolute;
  right: 4rem;
  top: 0;
  font-family: "iconfont" !important;
  font-size: 18rem;
  content: "\e618";
  transform: rotate(90deg);
  transition: all 0.3s;
  opacity: 0.2;
}

.active .qa-list-item-title::after {
  transform: rotate(-90deg);
}

.qa-list-item-content {
  margin-top: 10rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24rem;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
}

.active .qa-list-item-content {
  height: auto;
}

.qa-list-item-content>p {
  position: relative;
  padding-left: 12rem;
}

.qa-list-item-content>p::before {
  position: absolute;
  left: 4rem;
  top: 11rem;
  content: "";
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #6d6d6d;
}

.button-title {
  color: #6a6967;
  text-align: center;
  font-size: 40rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.96rem;
  transition: all 0.3s;
  cursor: pointer;
}

.button-title-active {
  color: #fff;
}

.radius-5 {
  border-radius: 5rem;
}

.section7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90rem 0 100rem 0;
  background: url(../assets/image/win/section7_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.section8-content {
  display: flex;
  justify-content: center;
  gap: 20rem;
  margin-top: 68rem;
  overflow: hidden;
}

.section8-item {
  /* padding: 12rem 12rem 28rem 12rem; */
  /* display: flex;
  flex-direction: column;
  gap: 24rem; */
  /* border: 1px solid var(--02042, #a52933);
  background: var(--02041, linear-gradient(180deg, #3d3c3c 0%, #161414 73.5%)); */
  cursor: pointer;
}

.section8-bg-top {
  position: absolute;
  top: -5rem;
  width: 1920rem;
  height: 100rem;
  z-index: 9997;
}

.section8-bg-bottom {
  position: absolute;
  bottom: -5rem;
  width: 1920rem;
  height: 100rem;
  z-index: 9997;
}

.section8-item-img {
  height: auto;
  width: 100%;
  /* width: 380rem;
  height: 254rem; */
}

.section8-item h4 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  text-align: center;
  width: 380rem;
}

.svg-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  background-image: url("../assets/image/win/play.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  cursor: pointer;
}


.cr-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
}

.cr-modal-content {
  position: relative;
  margin: 9% auto;
  padding: 0;
  width: 60%;
  max-width: 60%;
}

#imageModal .image-list {
  overflow: hidden;
}

#imageModal .cr-modal-content {
  position: relative;
  margin: 9% auto 0;
  padding: 0;
  width: 50%;
  max-width: 50%;
}

#imageModal .cr-modal-content img {
  width: 100%;
}

/* Swiper Navigation Button Styles */
#imageModal .swiper-button-prev,
#imageModal .swiper-button-next {
  top: 50%;
  width: auto;
  height: auto;
  margin-top: 0;
  color: white;
  /* 设置按钮颜色 */
  padding: 5px 10px;
  transform: translateY(-50%);
  opacity: 0.8;
}

#imageModal .swiper-button-prev {
  left: -70px;
}

#imageModal .swiper-button-next {
  right: -70px;
}

#imageModal .swiper-pagination {
  text-align: center;
  font-size: 16px;
  position: absolute;
  bottom: -30px;
  /* adjust if necessary */
  left: 0;
  width: 100%;
  color: #ffffff;
  font-family: auto;
}

.cr-modal video {
  width: 100%;
  /* 修改此处 */
  height: 100%;
  /* 修改此处 */
  display: block;
}

.cr-close {
  position: absolute;
  top: -40px;
  /* 将此按钮上移 */
  right: 10px;
  color: #aaa;
  font-size: 28px;
  cursor: pointer;
  padding: 5px;
}

.cr-close:hover,
.cr-close:focus {
  color: #fff;
}

.section6-step-box .buttons {
  margin-top: -14rem;
}

.section5-2025 {
  display: none;
}

.number-300 {
  height: 78rem;
  transform: translateY(8rem);
}

.number-30 {
  height: 78rem;
  transform: translateY(8rem);
}

.pre-light {
  position: relative;
  cursor: pointer;
  color: #f7c9a0 !important;
}

.win-my-tips {
  display: none;
  position: absolute;
  right: -74rem;
  bottom: -48rem;
  border-radius: 8rem;
  background: rgba(93, 35, 51, 0.8);
  padding: 12rem 20rem;
  color: #e6ddcf;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 9999999;
}

.win-my-tips2 {
  display: none;
  position: absolute;
  left: 14rem;
  bottom: -48rem;
  border-radius: 8rem;
  background: rgba(93, 35, 51, 0.8);
  padding: 12rem 20rem;
  color: #e6ddcf;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 9999999;
  width: max-content;
}

.pre-light:hover .win-my-tips {
  display: block;
}

.tips-box {
  position: relative;
}

.tips-box:hover .win-my-tips2 {
  display: block;
}

@media (max-width: 996px) {
  .section2f-vectors {
    display: flex;
    justify-content: center;
    gap: 10rem;
    margin: 26rem auto;
  }

  body {
    background-color: #000;
    background-image: url("../assets/image/win/bg_m.png");
    background-size: 100% auto;
    background-repeat: repeat-y;
  }

  .section1 {
    overflow: hidden;
  }

  .logo_banner {
    width: 499rem;
    height: 81rem;
  }

  .jiangbei_banner {
    width: 420rem;
    height: 455rem;
    margin-top: 180rem;
  }

  .b_left {
    position: absolute;
    width: 529rem;
    height: 246rem;
    left: 25rem;
    bottom: 119rem;
    z-index: 3;
  }

  @keyframes b_leftshow {
    0% {
      transform: translate(-180rem);
      opacity: 0;
    }

    100% {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes b_rightshow {
    0% {
      transform: translate(180rem);
      opacity: 0;
    }

    100% {
      transform: translate(0);
      opacity: 1;
    }
  }

  .b_right {
    position: absolute;
    width: 335rem;
    height: 310rem;
    left: 382rem;
    bottom: 119rem;
    z-index: 2;
  }

  .section1-title-box {
    margin-top: 0;
    width: 700rem;
    height: 119rem;
    background: url("../assets/image/win/title_bg_m.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding-bottom: 6rem;
  }

  .section1-intro,
  .section2-intro,
  .section2b-intro {
    margin-top: 70rem;
    font-weight: 400;
    line-height: 49rem;
    font-size: 24rem;
    padding: 0 25rem;
  }

  .hidden {
    display: block;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    margin: 0;
  }

  .section6-step-box .buttons {
    margin-top: 0;
  }

  .es .button-title {
    font-size: 34rem;
  }

  .section1 {
    padding-left: 15rem;
    padding-right: 15rem;
    height: 1096rem;
    background: url("../assets/image/win/section1_bg_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section1 h1 {
    font-size: 39rem;
    line-height: 48rem;
  }

  .en .section1 h1 {
    font-size: 31rem;
  }

  .th .section1 h1 {
    font-size: 23rem;
  }

  .section1 h1 .ebc {
    font-size: 55rem;
    line-height: 55rem;
  }

  .title-icon {
    width: 24rem;
    height: 24rem;
  }

  .subtitle {
    margin-top: 4rem;
    font-size: 24rem;
    font-weight: 500;
  }

  .en .subtitle {
    font-size: 16rem;
  }

  .subtitle-line-left,
  .subtitle-line-right {
    width: 60rem;
  }

  .en .subtitle-line-left,
  .en .subtitle-line-right {
    width: 20rem;
    flex: 1;
  }

  .section1-info-time {
    flex-direction: column;
    height: 368rem;
  }

  .reward-item-m {
    display: flex;
    align-items: flex-end;
    width: 287rem;
    height: 487rem;
    padding-bottom: 24rem;
  }

  .reward-item-m-1 {
    background: url(../assets/image/win/jiangbei1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .reward-item-m-2 {
    background: url(../assets/image/win/jiangbei2.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .reward-item-m-3 {
    background: url(../assets/image/win/jiangbei3.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .reward-item-m-4 {
    background: url(../assets/image/win/jiangbei4.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .reward-item-m-5 {
    background: url(../assets/image/win/jiangbei5.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .reward-item-m-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12rem;
    width: 100%;
    padding: 0 20rem;
    height: 151rem;
  }

  .section2-header {
    gap: 40rem;
  }

  .section2-card-value {
    margin-top: 4rem;
  }

  .section2f-card-box {
    /* display: block; */
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 18rem;
  }

  .section2f-card:nth-child(3) {
    margin-top: -88rem;
  }

  .section2b {
    height: auto;
    padding-bottom: 80rem;
    margin-top: 0;
  }

  .section2b-item {
    flex-shrink: 0;
  }

  .section2b-item:hover {
    transform: scale(1);
  }

  .section2b-content {
    display: none;
    /* width: 700rem;
    margin-left: 25rem;
    margin-right: 25rem;
    overflow-x: auto;
    justify-content: flex-start; */
  }

  .swiper-slide-active .section2b-item {
    transform: scale(1.3);
  }

  .section2b-content-m {
    position: relative;
    padding-top: 180rem;
    padding-bottom: 180rem;
    margin-top: 50rem;
  }

  .section2b-item-img {
    width: 100%;
    height: auto;
  }

  .section2b-video {
    width: auto;
    height: 100%;
    bottom: 0;
    top: unset;
  }

  .section3-group-card1 {
    width: 339rem;
  }

  .section3-group-card2 {
    width: 294rem;
  }

  .section3-content {
    /* margin: 116rem 46rem 162rem; */
    min-width: auto;
  }

  .section3 {
    background-position: 70%;
  }

  .section4-list {
    position: relative;
    overflow: hidden;
    width: 700rem;
    margin-top: 76rem;
  }

  .section4-table {
    width: 700rem;
  }

  .section4-table .tr,
  .section4-table .th {
    padding: 24rem 6rem;
  }

  .section4-table .tr .td {
    font-size: 16rem;
  }

  .section4-tips {
    font-size: 24rem;
    width: auto;
    padding: 0 25rem;
  }

  .section4-item {
    display: flex;
    flex-direction: column;
    width: 253rem;
    height: 689rem;
  }

  .section4-item-top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24rem;
  }

  .section4-item-rank {
    flex-shrink: 0;
    font-family: Gilroy;
    font-size: 52rem;
    font-style: italic;
    font-weight: 900;
    line-height: 30rem;
    color: #949699;
    /* text-transform: capitalize; */
  }

  .section4-item-rank-left,
  .section4-item-rank-right {
    width: 22rem;
    height: 42rem;
  }

  .section4-item-content {
    position: relative;
    height: 607rem;
    margin-top: 28rem;
    flex: 1;
    background: linear-gradient(136deg, #3d3c3c 18.97%, #1b1919 89.52%);
    padding: 24rem 26rem;
  }

  .section4-item-bg {
    position: absolute;
    right: 0;
    bottom: 4rem;
    width: 121rem;
    height: 188rem;
  }

  .section4-item-label {
    color: rgba(255, 255, 255, 0.4);
    font-size: 24rem;
    font-weight: 400;
    line-height: normal;
    margin-top: 12rem;
    display: flex;
    align-items: baseline;
    gap: 4rem;
  }

  .icon-a-tipsicon {
    font-size: 20rem;
  }

  .section4-item-label:nth-child(1) {
    margin-top: 0;
  }

  .section4-item-value {
    color: rgba(255, 255, 255, 0.7);
    font-size: 24rem;
    font-weight: 500;
    line-height: normal;
    padding: 6rem 0;
  }

  .section4-item-line {
    width: 100%;
    border-bottom: 1px dashed rgba(195, 194, 194, 0.4);
  }

  .section4-item-item-button {
    flex-shrink: 0;
    width: 100%;
    height: 72rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0, #642234 13.89%, #992142 113.89%);
    color: #fff;
    font-size: 28rem;
    font-style: normal;
    font-weight: 400;
  }

  .en .section4-item-item-button {
    font-size: 24rem;
  }

  .section4-item-item-button:hover {
    color: #fff;
  }

  .no-data {
    height: 607rem;
    font-size: 24rem;
    color: #fff;
  }

  .section5-time-line {
    position: relative;
    display: block;
    height: 960rem;
    background-image: url(../assets/image/win/section5_step_m.png);
    background-size: 74rem 960rem;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25rem;
  }

  .section5-2025 {
    display: block;
    width: 648rem;
    height: 206rem;
    position: absolute;
    left: 45rem;
    top: 70rem;
  }

  .section5-lines {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
    gap: 120rem;
  }

  .section5-line-item {
    flex-direction: row;
    width: 100%;
    gap: 12rem;
    justify-content: center;
    min-height: auto;
    padding: 0;
    border: none;
    background: none;
  }

  .en .section5-line-item {
    width: 100%;
  }

  .es .section5-line-item {
    padding: 0;
  }

  .section5-line-item .icon-Vector {
    font-size: 42rem;
    animation: none;
  }

  .section5-line-item .p2 {
    font-size: 32rem;
    width: 313rem;
    color: rgba(255, 255, 255, 0.9);
    text-align: left;
    padding: 0 30rem;
  }

  .en .section5-line-item .p2 {
    font-size: 26rem;
  }

  .section5-line-item .p1 {
    font-size: 32rem;
    width: 313rem;
    color: rgba(255, 255, 255, 0.9);
    text-align: right;
    padding: 0 30rem;
  }

  .en .section5-line-item .p1 {
    font-size: 28rem;
  }

  .section6-table,
  .section6-step-box,
  .section6-list,
  .qa-list {
    width: 720rem;
  }

  .section6-table td:first-child {
    width: 120px;
  }

  .section6-table thead th:last-child {
    background: linear-gradient(180deg,
        rgba(155, 33, 67, 0.6) 0%,
        rgba(93, 35, 51, 0.6) 82.5%);
  }

  .section6-table+.section6-table {
    margin-top: 12rem;
  }

  .section6-step-content {
    gap: 10rem;
    align-items: stretch;
  }

  .section6-step-box {
    padding: 52rem 28rem;
    margin-top: 50rem;
  }

  .section6-step-box .buttons {
    margin-top: 60rem;
  }

  .section6-step-item {
    width: 190rem;
    height: auto;
    min-height: 190rem;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 80rem;
    justify-content: flex-start;
  }

  .section6-step-item .p2,
  .section6-step-item h5 {
    padding: 0 10rem;
    line-height: 1.1em;
  }

  .section6-step-item .p2 {
    font-size: 20rem;
  }

  .en .section6-step-item h5 {
    font-size: 24rem;
  }

  .section6-right {
    width: 16rem;
    height: 51rem;
    align-self: center;
  }

  .mt-0 {
    margin-top: 0;
  }

  .section8 {
    display: block;
  }

  .section8-item {
    display: inline-flex;
    width: 404rem;
    min-height: 306rem;
  }

  .section8-list-box {
    margin: 67rem 135rem 0;
    padding: 0;
    overflow: hidden;
  }

  .section8-list .swiper-button-prev:after,
  .section8-list .swiper-rtl .swiper-button-next:after {
    content: "";
  }

  .section8-list .swiper-button-next:after,
  .section8-list .swiper-rtl .swiper-button-prev:after {
    content: "";
  }

  .swiper-news-prev {
    background-image: url("../assets/image/win/left.svg");
    width: 22rem !important;
    height: 44rem !important;
    background-size: cover;
    margin-left: 78rem;
  }

  .swiper-news-next {
    background-image: url("../assets/image/win/right.svg");
    width: 22rem !important;
    height: 44rem !important;
    background-size: cover;
    margin-right: 78rem;
  }

  .section2f,
  .section2,
  .section3,
  .section4,
  .section5,
  .section6,
  .section7,
  .section8 {
    padding: 100rem 0;
  }

  .section2 {
    display: block;
    overflow: hidden;
  }

  .section3 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .reward-list,
  .reward-list2 {
    position: relative;
    margin-top: 80rem;
  }

  .swiper-pagination {
    transform: translateY(80rem) !important;
  }

  .swiper-pagination-bullet {
    background: #a52933 !important;
    opacity: 0.5 !important;
  }

  .swiper-pagination-bullet-active {
    background: #a52933 !important;
    opacity: 1 !important;
  }

  .reward-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 254rem;
    height: 431rem;
  }

  .reward-item-jiangbei {
    z-index: 2;
  }

  .reward-item-1 .reward-item-content,
  .reward-item-2 .reward-item-content,
  .reward-item-3 .reward-item-content,
  .reward-item-4 .reward-item-content,
  .reward-item-5 .reward-item-content {
    z-index: 1;
    display: flex;
    margin-top: -10rem;
  }

  .reward-item .p1,
  .reward-item-m .p1 {
    text-align: center;
    font-size: 32rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
  }

  .reward-item .p2,
  .reward-item-m .p2 {
    display: block;
    text-align: center;
    font-size: 21rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2em;
  }

  .section1-info-time-label {
    margin-right: 0;
  }

  .tab-btns {
    gap: 30rem;
  }

  .step-img {
    width: 140rem;
    height: auto;
  }

  .section3-card {
    padding: 0rem 20rem 20rem;
  }

  .section3-card-step-value {
    max-width: 140rem;
    text-align: center;
  }

  .section2f-card {
    gap: 14rem;
  }

  .en .section2f-card {
    gap: 4rem;
  }

  .section2f-card .p1 {
    font-size: 32rem;
  }

  .section2f-card .p2 {
    font-size: 22rem;
    line-height: normal;
  }

  @keyframes section3-groupshow {
    0% {
      opacity: 0;
      transform: translate(-45rem);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes section3-cardshow {
    0% {
      opacity: 0;
      transform: translate(45rem);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  .section8-bg-top,
  .section8-bg-bottom {
    height: 40rem;
  }

  .cr-modal-content {
    width: 100%;
    max-width: 100%;
  }

  .cr-modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .group-button-left,
  .group-button-right {
    padding: 24rem 58rem 26rem;
    font-size: 26rem;
  }

  .section-button {
    padding: 24rem 78rem 26rem;
    font-size: 26rem;
  }

  .time-button {
    padding: 32rem 56rem 34rem;
    min-width: 350rem;
    font-size: 28rem;
  }

  .section6-list-title,
  .qa-list-title {
    font-size: 32rem;
  }

  .section6-table th {
    font-size: 28rem;
  }

  .section1-info-time-label,
  .section1-info-opentime,
  .section3-content .p1,
  .section2b-item h4,
  .section6-table td,
  .section6-list-item-title,
  .section6-list-item-content,
  .qa-list-item-title,
  .qa-list-item-content {
    font-size: 24rem;
    line-height: normal;
  }

  .section2b-item p {
    font-size: 20rem;
    line-height: normal;
  }
}

.font-weight-400 {
  font-weight: 400;
}

.red {
  color: #a52933;
}

.red-light {
  color: #ff4b4e;
}

.blue {
  color: #114291;
}

.inline-block {
  display: inline-block !important;
  width: auto !important;
}

.pt .section1 h1 {
  font-size: 34rem;
}

.es .section1 h1 {
  font-size: 38rem;
}

.vi .subtitle,
.pt .subtitle {
  font-size: 16rem;
  font-weight: 300;
}

.es .subtitle {
  font-size: 16rem;
  font-weight: 300;
}

.vi .section2f-card .p1,
.pt .section2f-card .p1 {
  font-size: 18rem;
}

.vi .section2f-card .p2,
.pt .section2f-card .p2 {
  font-size: 13rem;
}

.es .section2f-card .p1 {
  font-size: 20rem;
}

.es .section2f-card .p2 {
  font-size: 13rem;
}

.kr .subtitle {
  font-size: 18rem;
}

.kr .section2f-card .p1 {
  font-size: 26rem;
}

.kr .section2f-card .p2 {
  font-size: 19rem;
}

.jp .section2f-card .p1 {
  font-size: 22rem;
}

.jp .section2f-card .p2 {
  font-size: 14rem;
  line-height: 1.2;
}

@media (max-width: 996px) {

  .vi .section1 h1,
  .pt .section1 h1 {
    font-size: 20rem;
  }

  .vi .subtitle,
  .pt .subtitle {
    font-size: 14rem;
    font-weight: 300;
  }

  .es .section1 h1 {
    font-size: 20rem;
  }

  .es .subtitle {
    font-size: 14rem;
    font-weight: 300;
  }

  .kr .subtitle {
    font-size: 16rem;
  }

  .win-my-tips {
    right: unset;
    left: 0;
    bottom: unset;
    padding: 14rem 18rem;
    font-size: 22rem;
    max-width: 210rem;
  }

  .win-my-tips2 {
    padding: 14rem 18rem;
    font-size: 22rem;
  }

}
</style>